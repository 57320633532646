import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sf-widget" }
const _hoisted_2 = { class: "sf-title" }
const _hoisted_3 = { class: "rowPadding" }
const _hoisted_4 = { class: "mainFilterContainer" }
const _hoisted_5 = { class: "rowPadding" }
const _hoisted_6 = { class: "modTitle" }
const _hoisted_7 = { class: "buyFiltersContainer" }
const _hoisted_8 = { class: "sf-f1" }
const _hoisted_9 = { class: "sf-f2" }
const _hoisted_10 = { class: "sf-f3" }
const _hoisted_11 = { class: "sf-f4" }
const _hoisted_12 = { class: "sf-f5" }
const _hoisted_13 = { class: "summaryBuyContainer rowPadding" }
const _hoisted_14 = { class: "summaryBuyItem summary1 shadow" }
const _hoisted_15 = { class: "summaryCols" }
const _hoisted_16 = { class: "sumBuyCol1" }
const _hoisted_17 = { class: "sumBuyCol2" }
const _hoisted_18 = { class: "rowPadding" }
const _hoisted_19 = { class: "modTitle" }
const _hoisted_20 = { class: "sellFiltersContainer" }
const _hoisted_21 = { class: "sf-f6" }
const _hoisted_22 = { class: "sf-f7" }
const _hoisted_23 = { class: "sf-f8" }
const _hoisted_24 = { class: "sf-f9" }
const _hoisted_25 = { class: "sf-f10" }
const _hoisted_26 = { class: "summarySellContainer rowPadding" }
const _hoisted_27 = { class: "summarySellItem summary1 shadow" }
const _hoisted_28 = { class: "summarySellItem summary2 shadow" }
const _hoisted_29 = ["value"]
const _hoisted_30 = ["value"]
const _hoisted_31 = { class: "text-center" }
const _hoisted_32 = {
  key: 0,
  style: {"display":"absolute"}
}
const _hoisted_33 = { style: {"z-index":"-9999","width":"1px","height":"1px","opacity":"0"} }
const _hoisted_34 = { ref: "pdfContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumberButtons = _resolveComponent("InputNumberButtons")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_PdfTemplate = _resolveComponent("PdfTemplate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(_component_InputText, {
          inputValue: _ctx.investName,
          description: "",
          key: _ctx.inputForceUpdate,
          onChangedVal: _ctx.changedInvestName,
          class: "investName"
        }, null, 8, ["inputValue", "onChangedVal"])),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.flatArea,
              description: "POWIERZCHNIA [m2]",
              minValue: 1,
              maxValue: 200,
              step: 1,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedFlatArea
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", null, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.roomsCount,
              description: "LICZBA POKOI",
              minValue: 1,
              maxValue: 20,
              step: 1,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedRoomsCount
            }, null, 8, ["inputValue", "onChangedVal"]))
          ])
        ])
      ]),
      _cache[37] || (_cache[37] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("h2", null, [
            _createElementVNode("img", {
              style: {"width":"65px","vertical-align":"middle"},
              src: "http://kalkulator-hipoteczny.online/wp-content/uploads/2024/10/buyicon.png"
            }),
            _createTextVNode(" ZAKUP NIERUCHOMOŚCI ")
          ], -1)),
          _createElementVNode("div", null, [
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "filterLabel" }, "DATA", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_VueDatePicker, {
              clearable: false,
              format: "dd-MM-yyyy",
              modelValue: _ctx.buyDate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.buyDate) = $event)),
              "enable-time-picker": false
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.purchasePrice,
              description: "CENA NIERUCHOMOŚCI",
              minValue: 50000,
              maxValue: 2000000,
              step: 50000,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedPurchasePrice
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.purchasePriceGarage,
              description: "CENA GARAŻU",
              minValue: 1000,
              maxValue: 200000,
              step: 500,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedPurchasePriceGarage
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.purchasePriceBasement,
              description: "CENA KOMÓRKI LOKATORSKIEJ",
              minValue: 500,
              maxValue: 100000,
              step: 500,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedPurchasePriceBasement
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.renovationPrice,
              description: "CENA WYKOŃCZENIA",
              minValue: 500,
              maxValue: 100000,
              step: 500,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedRenovationPrice
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.interiorFinishingPrice,
              description: "CENA URZĄDZENIA",
              minValue: 0,
              maxValue: 2000000,
              step: 500,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedInteriorFinishingPrice
            }, null, 8, ["inputValue", "onChangedVal"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _cache[16] || (_cache[16] = _createElementVNode("h3", null, "PODSUMOWANIE ZAKUPU:", -1)),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", null, [
                _cache[8] || (_cache[8] = _createTextVNode(" Łączna cena zakupu: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalPurchasePrice, "PLN")), 1),
                _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                _cache[10] || (_cache[10] = _createTextVNode(" Łączny koszt inwestycji: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalCosts, "PLN")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("span", null, [
                _cache[11] || (_cache[11] = _createTextVNode(" Cena zakupu za m2: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaPurchasePrice, "PLN")), 1),
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _cache[13] || (_cache[13] = _createTextVNode(" Cena wykończenia za m2: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaRenovationPrice, "PLN")), 1),
                _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                _cache[15] || (_cache[15] = _createTextVNode(" Cena urządzenia za m2: ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaInteriorFinishingPrice, "PLN")), 1)
              ])
            ])
          ])
        ])
      ]),
      _cache[38] || (_cache[38] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _cache[19] || (_cache[19] = _createElementVNode("h2", null, [
            _createElementVNode("img", {
              style: {"width":"65px","vertical-align":"middle"},
              src: "http://kalkulator-hipoteczny.online/wp-content/uploads/2024/10/sellicon.png"
            }),
            _createTextVNode(" SPRZEDAŻ NIERUCHOMOŚCI ")
          ], -1)),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _cache[17] || (_cache[17] = _createElementVNode("span", { class: "filterLabel" }, "DATA", -1)),
              _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_VueDatePicker, {
                clearable: false,
                format: "dd-MM-yyyy",
                modelValue: _ctx.sellDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sellDate) = $event)),
                "enable-time-picker": false
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.sellPrice,
              description: "CENA NIERUCHOMOŚCI",
              minValue: 50000,
              maxValue: 2000000,
              step: 50000,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedSellPrice
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_22, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.sellPriceGarage,
              description: "CENA GARAŻU",
              minValue: 1000,
              maxValue: 200000,
              step: 500,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedSellPriceGarage
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_23, [
            (_openBlock(), _createBlock(_component_InputNumberButtons, {
              inputValue: _ctx.sellPriceBasement,
              description: "CENA KOMÓRKI LOKATORSKIEJ",
              minValue: 500,
              maxValue: 100000,
              step: 500,
              key: _ctx.inputForceUpdate,
              onChangedVal: _ctx.changedSellPriceBasement
            }, null, 8, ["inputValue", "onChangedVal"]))
          ]),
          _createElementVNode("div", _hoisted_24, [
            _cache[20] || (_cache[20] = _createTextVNode(" Łączna cena sprzedaży: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalSellPrice, "PLN")), 1)
          ]),
          _createElementVNode("div", _hoisted_25, [
            _cache[21] || (_cache[21] = _createTextVNode(" Cena sprzedaży za m2: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaSellPrice, "PLN")), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _cache[27] || (_cache[27] = _createElementVNode("h3", null, [
            _createTextVNode("Zysk "),
            _createElementVNode("span", { class: "color1" }, "przed opodatkowaniem")
          ], -1)),
          _createElementVNode("div", null, [
            _cache[22] || (_cache[22] = _createTextVNode(" - z inwestycji: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.investProfit, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[23] || (_cache[23] = _createTextVNode(" - za m2: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaProfit, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[24] || (_cache[24] = _createTextVNode(" - z mieszkania (po remoncie): ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.flatProfitAfterRenovation, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[25] || (_cache[25] = _createTextVNode(" - z miejsca postojowego/garażu: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.garageProfit, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[26] || (_cache[26] = _createTextVNode(" - za komórkę lokatorską: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.basementProfit, "PLN")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _cache[35] || (_cache[35] = _createElementVNode("h3", null, [
            _createTextVNode("Zysk "),
            _createElementVNode("span", { class: "color2" }, "po opodatkowaniu")
          ], -1)),
          _createElementVNode("div", null, [
            _cache[28] || (_cache[28] = _createTextVNode(" - stawka podatkowa: ")),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.incomeTax) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomeTaxItems, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: item.id,
                  value: item.id
                }, _toDisplayString(item.title), 9, _hoisted_29))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.incomeTax]
            ])
          ]),
          _createElementVNode("div", null, [
            _cache[29] || (_cache[29] = _createTextVNode(" - wartość podatku dochodowego: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.incomeTaxValue, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[30] || (_cache[30] = _createTextVNode(" - podatek zdrowotny: ")),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.healthTax) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.healthTaxItems, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: item.id,
                  value: item.id
                }, _toDisplayString(item.title), 9, _hoisted_30))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.healthTax]
            ])
          ]),
          _createElementVNode("div", null, [
            _cache[31] || (_cache[31] = _createTextVNode(" - wartość podatku zdrowotnego: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.healthTaxValue, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[32] || (_cache[32] = _createTextVNode(" - zysk po podatku: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsCurrency(_ctx.afterTaxProfit, "PLN")), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[33] || (_cache[33] = _createTextVNode(" - stopa zwrotu inwestycji: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.showAsPercent2(_ctx.profitRate)), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[34] || (_cache[34] = _createTextVNode(" - czas trwania (w m-c): ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.profitPeriod), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("button", {
          class: "sf-mortgageCheckBtn",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.printPdf && _ctx.printPdf(...args)))
        }, _cache[36] || (_cache[36] = [
          _createElementVNode("div", { class: "sf-mortgageCheckBtnContainer" }, [
            _createElementVNode("div", null, "pobierz PDF")
          ], -1)
        ]))
      ]),
      (_ctx.pdfGenerating)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createVNode(_component_PdfTemplate, {
                  generationDate: _ctx.generationDate,
                  investName: _ctx.investName,
                  flatArea: _ctx.flatArea,
                  roomsCount: _ctx.roomsCount,
                  buyDate: _ctx.buyDate,
                  purchasePrice: _ctx.purchasePrice,
                  purchasePriceGarage: _ctx.purchasePriceGarage,
                  purchasePriceBasement: _ctx.purchasePriceBasement,
                  totalPurchasePrice: _ctx.totalPurchasePrice,
                  renovationPrice: _ctx.renovationPrice,
                  interiorFinishingPrice: _ctx.interiorFinishingPrice,
                  totalCosts: _ctx.totalCosts,
                  areaPurchasePrice: _ctx.areaPurchasePrice,
                  areaRenovationPrice: _ctx.areaRenovationPrice,
                  areaInteriorFinishingPrice: _ctx.areaInteriorFinishingPrice,
                  sellDate: _ctx.sellDate,
                  sellPrice: _ctx.sellPrice,
                  sellPriceGarage: _ctx.sellPriceGarage,
                  sellPriceBasement: _ctx.sellPriceBasement,
                  totalSellPrice: _ctx.totalSellPrice,
                  areaSellPrice: _ctx.areaSellPrice,
                  investProfit: _ctx.investProfit,
                  areaProfit: _ctx.areaProfit,
                  flatProfitAfterRenovation: _ctx.flatProfitAfterRenovation,
                  garageProfit: _ctx.garageProfit,
                  basementProfit: _ctx.basementProfit,
                  incomeTax: _ctx.incomeTax,
                  incomeTaxValue: _ctx.incomeTaxValue,
                  healthTax: _ctx.healthTax,
                  healthTaxValue: _ctx.healthTaxValue,
                  afterTaxProfit: _ctx.afterTaxProfit,
                  profitRate: _ctx.profitRate,
                  profitPeriod: _ctx.profitPeriod
                }, null, 8, ["generationDate", "investName", "flatArea", "roomsCount", "buyDate", "purchasePrice", "purchasePriceGarage", "purchasePriceBasement", "totalPurchasePrice", "renovationPrice", "interiorFinishingPrice", "totalCosts", "areaPurchasePrice", "areaRenovationPrice", "areaInteriorFinishingPrice", "sellDate", "sellPrice", "sellPriceGarage", "sellPriceBasement", "totalSellPrice", "areaSellPrice", "investProfit", "areaProfit", "flatProfitAfterRenovation", "garageProfit", "basementProfit", "incomeTax", "incomeTaxValue", "healthTax", "healthTaxValue", "afterTaxProfit", "profitRate", "profitPeriod"])
              ], 512)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}