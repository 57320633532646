
import { defineComponent } from "vue";

import { showAsCurrency } from "@/services/showAsCurrency";
import { showAsPercent2 } from "@/services/showAsPercent";

import IconSpeedfin from "@/components/IconSpeedfin.vue";

export default defineComponent({
  name: "PdfTemplate",
  props: [
    "generationDate",
    "investName",
    "flatArea",
    "roomsCount",
    "buyDate",
    "purchasePrice",
    "purchasePriceGarage",
    "purchasePriceBasement",
    "totalPurchasePrice",
    "renovationPrice",
    "interiorFinishingPrice",
    "totalCosts",
    "areaPurchasePrice",
    "areaRenovationPrice",
    "areaInteriorFinishingPrice",
    "sellDate",
    "sellPrice",
    "sellPriceGarage",
    "sellPriceBasement",
    "totalSellPrice",
    "areaSellPrice",
    "investProfit",
    "areaProfit",
    "flatProfitAfterRenovation",
    "garageProfit",
    "basementProfit",
    "incomeTax",
    "incomeTaxValue",
    "healthTax",
    "healthTaxValue",
    "afterTaxProfit",
    "profitRate",
    "profitPeriod",
  ],
  components: {
    IconSpeedfin,
  },
  setup: function () {
    return {
      showAsCurrency,
      showAsPercent2,
    };
  },
});
