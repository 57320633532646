
import { defineComponent, onMounted, ref, toRaw, watch } from "vue";

export default defineComponent({
  name: "InputText",
  props: {
    inputValue: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: ["changedVal"],
  setup: function (props, ctx) {
    const localInputValue = ref("");
    const tmpVal = ref(props.inputValue);

    watch(localInputValue, (newValue) => {
      localInputValue.value = newValue;

      ctx.emit("changedVal", localInputValue.value);
    });

    onMounted(() => {
      localInputValue.value = toRaw(tmpVal.value);
    });

    return {
      localInputValue,
    };
  },
});
