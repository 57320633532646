
import { defineComponent } from "vue";
import WebWidget from "./components/WebWidget.vue";

export default defineComponent({
  name: "App",
  components: {
    WebWidget,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    campaignId: {
      type: String,
      required: false,
      default: "default",
    },
  },
});
