import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebWidget = _resolveComponent("WebWidget")!

  return (_openBlock(), _createBlock(_component_WebWidget, {
    title: _ctx.title,
    "campaign-id": _ctx.campaignId,
    class: "WidgetComponent"
  }, null, 8, ["title", "campaign-id"]))
}