import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filterLabel" }
const _hoisted_2 = { class: "inputContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.description), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrementVal && _ctx.decrementVal(...args))),
        class: "buttonDecrement shadow"
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", null, "-", -1)
      ])),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localInputValue) = $event)),
        class: "inputField shadow"
      }, null, 512), [
        [_vModelText, _ctx.localInputValue]
      ]),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.incrementVal && _ctx.incrementVal(...args))),
        class: "buttonIncrement shadow"
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", null, "+", -1)
      ]))
    ])
  ]))
}