import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pdfPage" }
const _hoisted_2 = { class: "text-right" }
const _hoisted_3 = { class: "printInvestName" }
const _hoisted_4 = { class: "rowPrint2Cols" }
const _hoisted_5 = { class: "rowItem" }
const _hoisted_6 = { class: "rowItem" }
const _hoisted_7 = { class: "rowPrint" }
const _hoisted_8 = { class: "rowPrint" }
const _hoisted_9 = { class: "rowPrint" }
const _hoisted_10 = { class: "rowPrint" }
const _hoisted_11 = { class: "rowPrint bold" }
const _hoisted_12 = { class: "rowPrint" }
const _hoisted_13 = { class: "rowPrint" }
const _hoisted_14 = { class: "rowPrint bold" }
const _hoisted_15 = { class: "rowPrint" }
const _hoisted_16 = { class: "rowPrint" }
const _hoisted_17 = { class: "rowPrint" }
const _hoisted_18 = { class: "rowPrint" }
const _hoisted_19 = { class: "rowPrint" }
const _hoisted_20 = { class: "rowPrint" }
const _hoisted_21 = { class: "rowPrint" }
const _hoisted_22 = { class: "rowPrint bold" }
const _hoisted_23 = { class: "rowPrint bold" }
const _hoisted_24 = { class: "rowPrint colSpace noBorder" }
const _hoisted_25 = { class: "printSummaryItem" }
const _hoisted_26 = { class: "rowPrint bold" }
const _hoisted_27 = { class: "rowPrint" }
const _hoisted_28 = { class: "rowPrint" }
const _hoisted_29 = { class: "rowPrint" }
const _hoisted_30 = { class: "rowPrint" }
const _hoisted_31 = { class: "printSummaryItem" }
const _hoisted_32 = { class: "rowPrint" }
const _hoisted_33 = { class: "rowPrint" }
const _hoisted_34 = { class: "rowPrint" }
const _hoisted_35 = { class: "rowPrint" }
const _hoisted_36 = { class: "rowPrint bold" }
const _hoisted_37 = { class: "rowPrint bold" }
const _hoisted_38 = { class: "rowPrint" }
const _hoisted_39 = { class: "footerPrint" }
const _hoisted_40 = { class: "genDate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSpeedfin = _resolveComponent("IconSpeedfin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconSpeedfin)
    ]),
    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "printTitle1" }, "Podsumowanie wyliczeń", -1)),
    _cache[35] || (_cache[35] = _createElementVNode("div", { class: "printTitle2" }, "DLA FLIPPERA", -1)),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.investName), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[0] || (_cache[0] = _createTextVNode(" Powierzchnia [m2]: ")),
        _createElementVNode("strong", null, _toDisplayString(_ctx.flatArea), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createTextVNode(" Liczba pokoi: ")),
        _createElementVNode("strong", null, _toDisplayString(_ctx.roomsCount), 1)
      ])
    ]),
    _cache[36] || (_cache[36] = _createElementVNode("div", { class: "catTitle" }, "Zakup", -1)),
    _createElementVNode("div", _hoisted_7, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, "Data zakupu nieruchomości:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.buyDate.toLocaleDateString("pl-PL")), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[3] || (_cache[3] = _createElementVNode("div", null, "Cena zakupu mieszkania:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.purchasePrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[4] || (_cache[4] = _createElementVNode("div", null, "Cena zakupu miejsca postojowego/garażu:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.purchasePriceGarage, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[5] || (_cache[5] = _createElementVNode("div", null, "Cena zakupu komórki lokatorskiej:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.purchasePriceBasement, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[6] || (_cache[6] = _createElementVNode("div", null, "Łączna cena zakupu nieruchomości:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalPurchasePrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[7] || (_cache[7] = _createElementVNode("div", null, "Kwota przeznaczona na wykończenie:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.renovationPrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[8] || (_cache[8] = _createElementVNode("div", null, "Kwota przeznaczona na urządzenie:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.interiorFinishingPrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[9] || (_cache[9] = _createElementVNode("div", null, "Łączny koszt inwestycji:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalCosts, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_15, [
      _cache[10] || (_cache[10] = _createElementVNode("div", null, "Cena zakupu za m2:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaPurchasePrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _cache[11] || (_cache[11] = _createElementVNode("div", null, "Cena wykończenia za m2:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaRenovationPrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _cache[12] || (_cache[12] = _createElementVNode("div", null, "Cena urządzenia za m2:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaInteriorFinishingPrice, "PLN")), 1)
    ]),
    _cache[37] || (_cache[37] = _createElementVNode("div", { class: "catTitle title2" }, "Sprzedaż:", -1)),
    _createElementVNode("div", _hoisted_18, [
      _cache[13] || (_cache[13] = _createElementVNode("div", null, "Data sprzedaży nieruchomości:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.sellDate.toLocaleDateString("pl-PL")), 1)
    ]),
    _createElementVNode("div", _hoisted_19, [
      _cache[14] || (_cache[14] = _createElementVNode("div", null, "Cena sprzedaży mieszkania:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.sellPrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _cache[15] || (_cache[15] = _createElementVNode("div", null, "Cena sprzedaży miejsca postojowego/garażu:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.sellPriceGarage, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_21, [
      _cache[16] || (_cache[16] = _createElementVNode("div", null, "Cena sprzedaży komórki lokatorskiej:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.sellPriceBasement, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_22, [
      _cache[17] || (_cache[17] = _createElementVNode("div", null, "Łączna cena sprzedaży nieruchomości:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.totalSellPrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_23, [
      _cache[18] || (_cache[18] = _createElementVNode("div", null, "Cena sprzedaży za m2:", -1)),
      _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaSellPrice, "PLN")), 1)
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "catTitle title3" }, "Zysk przed zapłatą podatku", -1)),
        _createElementVNode("div", _hoisted_26, [
          _cache[19] || (_cache[19] = _createElementVNode("div", null, "Zysk z inwestycji:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.investProfit, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_27, [
          _cache[20] || (_cache[20] = _createElementVNode("div", null, "z m2:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.areaProfit, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_28, [
          _cache[21] || (_cache[21] = _createElementVNode("div", null, "za mieszkanie (po remontach):", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.flatProfitAfterRenovation, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_29, [
          _cache[22] || (_cache[22] = _createElementVNode("div", null, "za miejsca postojowe/garaż:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.garageProfit, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_30, [
          _cache[23] || (_cache[23] = _createElementVNode("div", null, "za komórkę lokatorską:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.basementProfit, "PLN")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _cache[32] || (_cache[32] = _createElementVNode("div", { class: "catTitle title4" }, "Zysk po opłaceniu podatku", -1)),
        _createElementVNode("div", _hoisted_32, [
          _cache[25] || (_cache[25] = _createElementVNode("div", null, "Stawka podatkowa:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.incomeTax) + " %", 1)
        ]),
        _createElementVNode("div", _hoisted_33, [
          _cache[26] || (_cache[26] = _createElementVNode("div", null, "Wartość podatku dochodowego:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.incomeTaxValue, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_34, [
          _cache[27] || (_cache[27] = _createElementVNode("div", null, "Podatek zdrowotny:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.healthTax) + " %", 1)
        ]),
        _createElementVNode("div", _hoisted_35, [
          _cache[28] || (_cache[28] = _createElementVNode("div", null, "Wartość podatku zdrowotnego:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.healthTaxValue, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_36, [
          _cache[29] || (_cache[29] = _createElementVNode("div", null, "Zysk po podatkach:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsCurrency(_ctx.afterTaxProfit, "PLN")), 1)
        ]),
        _createElementVNode("div", _hoisted_37, [
          _cache[30] || (_cache[30] = _createElementVNode("div", null, "Stopa zwrotu inwestycji:", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.showAsPercent2(_ctx.profitRate)), 1)
        ]),
        _createElementVNode("div", _hoisted_38, [
          _cache[31] || (_cache[31] = _createElementVNode("div", null, "Czas trwania (w mc):", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.profitPeriod), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_39, [
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("span", null, "Wyliczenia na dzień: " + _toDisplayString(_ctx.generationDate), 1)
      ]),
      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "bold text-right" }, [
        _createTextVNode(" Masz pytania? Skontaktuj się ze mną:"),
        _createElementVNode("br"),
        _createTextVNode("+48 723 404 404 ")
      ], -1))
    ])
  ]))
}